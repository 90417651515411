import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Version from "../../assets/images/version_tag.svg";
import StatusChips from "../common/StatusChips";
import axios from "axios";
import download from "../../assets/images/download.svg";
import { saveAs } from "file-saver";
import ResolutionChip from "../securityAdvisory/resolutionChips";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";

function formatDate(updatedAt) {
  const date = new Date(updatedAt);

  const day = String(date.getUTCDate()).padStart(2, "0");
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const year = String(date.getUTCFullYear()).slice(-2);

  return `${year}-${month}-${day}`;
}

function formatTime(updatedAt) {
  const date = new Date(updatedAt);

  date.setMinutes(date.getMinutes() + 330); // Adding 330 minutes for GMT+5:30

  let hours = date.getUTCHours();
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  const period = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12; // Convert to 12-hour format

  return `${String(hours).padStart(2, "0")}:${minutes} ${period} GMT + 5:30`;
}

function Vulnerabilities({ versions, flag }) {
  const navigate = useNavigate();
  const BUILD_SERVER_FILE_URL = "https://build.podarmor.org/files";
  const [search, setSearch] = useState("");
  const [selectedVersion, setSelectedVersion] = useState(
    versions.filter((row) => row.arch == "amd64 + arm64")[0].version,
  );
  const [debug, setDebug] = useState(false);
  const [vulnerabilities, setVulnerabilities] = useState([]);
  const [displayedVulnerabilities, setDisplayedVulnerabilities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showFixed, setShowFixed] = useState(true);
  const [slug, _setSlug] = useState(
    versions.filter((row) => row.arch == "amd64 + arm64")[0].slug,
  );

  const handleCveClick = (cve) => {
    navigate(`/cve-details/${cve}`);
  };

  useEffect(() => {
    const fetchVulnerabilities = async () => {
      const selectedVersionData = versions.find(
        (v) => v.version === selectedVersion,
      );

      if (
        selectedVersionData &&
        selectedVersionData.id &&
        flag == "bitchlasagna"
      ) {
        setLoading(true);
        setDebug(true);
        try {
          const response = await axios.get(
            `/api/data/image/${selectedVersionData.id}/vulns`,
          );

          // Process the vulnerabilities to keep only the latest status
          const processedVulnerabilities = response.data["packageVulns"]
            .filter((vuln) => vuln?.vuln.severity !== "Negligible") // Filter out "Negligible" vulnerabilities
            .map((vuln) => {
              // Find the latest status by sorting statuses by updatedAt in descending order
              const latestStatus = vuln.status.sort(
                (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt),
              )[0];
              return { ...vuln, status: latestStatus };
            });
          setVulnerabilities(processedVulnerabilities);
        } catch (err) {
          console.error("Failed to fetch vulnerabilities:", err);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchVulnerabilities();
  }, [selectedVersion, versions]);

  useEffect(() => {
    if (search.trim() == "") {
      setDisplayedVulnerabilities(vulnerabilities);
    } else {
      const lowercasedSearch = search?.toLowerCase();
      setDisplayedVulnerabilities(
        vulnerabilities.filter(
          (row) =>
            row.vuln.CVE.toLowerCase().includes(lowercasedSearch) ||
            row.packageName.toLowerCase().includes(lowercasedSearch),
        ),
      );
    }
  }, [search, vulnerabilities]);

  const handleShowFixed = () => {
    setShowFixed(!showFixed);
    if (showFixed) {
      setDisplayedVulnerabilities(
        vulnerabilities.filter(
          (vuln) =>
            vuln.status.status == "UNDER_INVESTIGATION" ||
            vuln.status.status == "AFFECTED",
        ),
      );
    } else {
      setDisplayedVulnerabilities(vulnerabilities);
    }
  };
  async function handleDownload() {
    try {
      const response = await axios.get(
        `${BUILD_SERVER_FILE_URL}/${slug.split(":")[0]}_${selectedVersion}/vulnerabilities.json`,
        { responseType: "blob" }, // Important for file downloads
      );
      const fileName = "vulnerabilities.json"; // Set your desired file name
      saveAs(response.data, fileName);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  }
  return (
    <Paper sx={{ padding: 3, mb: 4, boxShadow: "none" }}>
      <div
        style={{
          display: "flex",
          gap: "1rem",
          marginBottom: "1rem",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", gap: "1rem", marginBottom: "1rem" }}>
          <Select
            renderValue={(value) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={Version}
                  alt="version"
                  style={{ marginRight: "0.5rem" }}
                />
                <span style={{ color: "#A0AEC0" }}>Version:</span>
                <span style={{ color: "black", marginLeft: "0.3rem" }}>
                  {value}
                </span>
              </div>
            )}
            onChange={(e) => setSelectedVersion(e.target.value)}
            displayEmpty
            value={selectedVersion}
            variant="outlined"
            sx={{ borderRadius: 2, pr: 3 }}
          >
            {[
              ...new Set(
                versions
                  .filter((row) => row.arch == "amd64 + arm64")
                  .map((v) => v.version),
              ),
            ].map((version) => (
              <MenuItem key={version} value={version}>
                {version}
              </MenuItem>
            ))}
          </Select>
          <TextField
            variant="outlined"
            placeholder="Search CVE"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon sx={{ fill: "#A0AEC0" }} />
                </InputAdornment>
              ),
            }}
            sx={{
              borderRadius: 2,
              "& .MuiOutlinedInput-root": {
                borderRadius: "16px",
              },
            }}
          />
          {debug && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                checked={showFixed} // Controlled by state
                onChange={handleShowFixed} // Event handler
                inputProps={{ "aria-label": "controlled" }}
              />
              <Typography>Show Fixed</Typography>
            </div>
          )}
        </div>
        <Button
          variant="contained"
          onClick={handleDownload}
          sx={{
            backgroundColor: "#4FD1C5",
            color: "white",
            fontWeight: 600,
            marginBottom: 2,
            boxShadow: 0,
          }}
        >
          <img
            src={download}
            alt="download"
            style={{ marginRight: "0.5rem" }}
          />
          DOWNLOAD Vulnerabilities
        </Button>
      </div>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  border: "none",
                  backgroundColor: "#F8F9FA!important",
                  pt: 1.5,
                  pb: 1.5,
                }}
              >
                <Typography variant="body2" color="#A0AEC0" fontWeight={600}>
                  CVE ID
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  border: "none",
                  backgroundColor: "#F8F9FA!important",
                  pt: 1.5,
                  pb: 1.5,
                }}
              >
                <Typography variant="body2" color="#A0AEC0" fontWeight={600}>
                  SEVERITY
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  border: "none",
                  backgroundColor: "#F8F9FA!important",
                  pt: 1.5,
                  pb: 1.5,
                }}
              >
                <Typography variant="body2" color="#A0AEC0" fontWeight={600}>
                  PACKAGE
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  border: "none",
                  backgroundColor: "#F8F9FA!important",
                  pt: 1.5,
                  pb: 1.5,
                }}
              >
                <Typography variant="body2" color="#A0AEC0" fontWeight={600}>
                  FIXED VERSION
                </Typography>
              </TableCell>
              {debug && (
                <TableCell
                  sx={{
                    border: "none",
                    backgroundColor: "#F8F9FA!important",
                    pt: 1.5,
                    pb: 1.5,
                  }}
                >
                  <Typography variant="body2" color="#A0AEC0" fontWeight={600}>
                    STATUS
                  </Typography>
                </TableCell>
              )}
              <TableCell
                sx={{
                  border: "none",
                  backgroundColor: "#F8F9FA!important",
                  pt: 1.5,
                  pb: 1.5,
                }}
              >
                {" "}
                <Typography variant="body2" color="#A0AEC0" fontWeight={600}>
                  LAST CHANGED
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <Typography variant="body1">Loading...</Typography>
                </TableCell>
              </TableRow>
            ) : displayedVulnerabilities.length > 0 ? (
              displayedVulnerabilities
                // .filter(
                //   (vuln, index) =>
                //     vuln?.severity !== "Negligible" && index != undefined,
                // )
                .map((vuln, index) => (
                  <React.Fragment key={index}>
                    <TableRow key={index}>
                      <TableCell
                        sx={{
                          border: "none",
                          cursor: "pointer",
                          "&:hover": {
                            color: "#4FD1C5",
                            "& .MuiChip-label": {
                              color: "#4FD1C5",
                              fontWeight: "600",
                            },
                            "& .MuiTypography-root": {
                              color: "#4FD1C5",
                            },
                          },
                        }}
                        onClick={() => handleCveClick(vuln.vulnId)}
                      >
                        <Typography variant="body1" sx={{ fontWeight: 600 }}>
                          {vuln.vuln.CVE}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "none",
                        }}
                      >
                        <StatusChips severity={vuln.vuln.severity} />
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "none",
                        }}
                      >
                        <Typography variant="body1" sx={{ fontWeight: 500 }}>
                          {vuln.packageName}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "none",
                        }}
                      >
                        <Typography variant="body1" sx={{ fontWeight: 500 }}>
                          {vuln.status.fixedVersion}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "none",
                        }}
                      >
                        <Typography variant="body1" sx={{ fontWeight: 500 }}>
                          <ResolutionChip status={vuln.status.status} />
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          border: "none",
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: 600, marginBottom: "2px" }}
                        >
                          {formatDate(vuln.updatedAt)}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ fontWeight: 500, color: "#A0AEC0" }}
                        >
                          {formatTime(vuln.updatedAt)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))
            ) : (
              <TableRow width="100%">
                <TableCell colSpan={5} align="center" width="100%">
                  <Typography variant="body1">
                    No Vulnerabilities found
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default Vulnerabilities;
