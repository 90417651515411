import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TextField,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import StatusChips from "../common/StatusChips";
import ResolutionChip from "./resolutionChips";
import { useNavigate } from "react-router-dom";

function CVEs({ displayedVulnerabilities }) {
  const navigate = useNavigate();

  const convertToRelativeTime = (timestampStr) => {
    const timestamp = new Date(timestampStr);
    const now = new Date();
    const timeDiff = now - timestamp;

    if (timeDiff < 24 * 60 * 60 * 1000) {
      const hoursAgo = Math.floor(timeDiff / (1000 * 60 * 60));
      return hoursAgo === 0
        ? "Just now"
        : `${hoursAgo} ${hoursAgo === 1 ? "hour" : "hours"} ago`;
    }

    const day = String(timestamp.getDate()).padStart(2, "0");
    const month = String(timestamp.getMonth() + 1).padStart(2, "0");
    const year = timestamp.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const [search, setSearch] = useState("");

  const filteredVulnerabilities = displayedVulnerabilities.filter((vuln) =>
    vuln.vuln.CVE.toLowerCase().includes(search?.toLowerCase()) || vuln.packageName.toLowerCase().includes(search?.toLowerCase()),
  );

  const handleCveClick = (cve) => {
    navigate(`/cve-details/${cve}`);
  };

  return (
    <Paper
      sx={{
        mb: 4,
        p: 3,
        boxShadow: "none",
        maxHeight: "70vh",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "1rem",
          marginBottom: "1rem",
          justifyContent: "space-between",
        }}
      >
        <TextField
          variant="outlined"
          placeholder="Search CVE"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon sx={{ fill: "#A0AEC0" }} />
              </InputAdornment>
            ),
          }}
          sx={{
            borderRadius: 2,
            "& .MuiOutlinedInput-root": {
              borderRadius: "16px",
            },
          }}
        />
      </div>
      <TableContainer
        sx={{
          maxHeight: "65vh",
          overflowY: "auto",
          borderRadius: "8px",
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {[
                "CVE ID",
                "SEVERITY",
                "AFFECTED PACKAGE",
                "STATUS",
                "PUBLISHED",
                "LAST CHANGED",
              ].map((header) => (
                <TableCell
                  key={header}
                  sx={{
                    border: "none",
                    backgroundColor: "#F8F9FA!important",
                    pt: 1.5,
                    pb: 1.5,
                  }}
                >
                  <Typography variant="body2" color="#A0AEC0" fontWeight={600}>
                    {header}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredVulnerabilities.length > 0 ? (
              filteredVulnerabilities.map((vuln) => (
                <TableRow key={vuln.id}>
                  <TableCell
                    sx={{
                      border: "none",
                      cursor: "pointer",
                      "&:hover": {
                        color: "#4FD1C5",
                        "& .MuiChip-label": {
                          color: "#4FD1C5",
                          fontWeight: "600",
                        },
                        "& .MuiTypography-root": {
                          color: "#4FD1C5",
                        },
                      },
                    }}
                    onClick={() => handleCveClick(vuln.vulnId)}
                  >
                    <Typography variant="body1" sx={{ fontWeight: 600 }}>
                      {vuln.vuln.CVE}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ border: "none" }}>
                    <StatusChips severity={vuln.vuln.severity} />
                  </TableCell>
                  <TableCell sx={{ border: "none" }}>
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      {vuln.packageName}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ border: "none" }}>
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      <ResolutionChip status={vuln.status[0].status} />
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ border: "none" }}>
                    <Typography variant="body1" sx={{ fontWeight: 600 }}>
                      {convertToRelativeTime(vuln.createdAt)}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ border: "none" }}>
                    <Typography variant="body1" sx={{ fontWeight: 600 }}>
                      {convertToRelativeTime(vuln.updatedAt)}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <Typography variant="body1" color="textSecondary">
                    No vulnerabilities match your search.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default CVEs;
